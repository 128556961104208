import { Col, Row } from "react-styled-flexboxgrid";

import Layout from "../components/layout/Layout";
import React from "react";
import { StyledGrid } from "../helpers/helpers.js";

const NotFoundPage = () => (
  <Layout>
    <StyledGrid>
      <Row>
        <Col xs={12}>
          <h1>Introuvable</h1>
          <p>Cette page n'existe pas.</p>
        </Col>
      </Row>
    </StyledGrid>
  </Layout>
);

export default NotFoundPage;
